import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from 'components/ui/sheet';
import { ActivityItem } from '../BillingActivityTabContent/column';
import { useQuery } from '@apollo/client';
import {
  Marketplace,
  ShopifyProduct,
  BillingItemType,
  Amount,
  Currency,
  CommissionStatus,
} from 'app/graphql/generated/admin/graphql';
import { ADMIN_ORDER_BY_ID } from 'app/graphql';
import { ProductCard } from '../ProductCard';
import { Section, SectionContainer, SectionContent, SectionHeader } from './style';
import { CardTitle } from 'components/ui/card';
import { OrderBreakdownCard } from '../OrderBreakdownCard';
import { useMemo } from 'react';
import { CommissionCard } from '../CommissionCard';
import { ScrollArea, ScrollBar } from 'components/ui/scrolll-area';
import { constantCase } from 'change-case';
import { useAppSelector } from 'app/store';
import { selectUserInfo } from 'app/store/user';

type Props = {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  item?: ActivityItem;
};

export const ActivityDetailSheet = ({ open, onOpenChange, item }: Props) => {
  const user = useAppSelector(selectUserInfo);
  const { data } = useQuery(ADMIN_ORDER_BY_ID, {
    skip: !item,
    context: {
      clientName: 'admin',
    },
    variables: {
      id: item?.orderId.toString() ?? '',
    },
  });

  const order = data?.orderById;

  const products = useMemo(() => {
    return (
      order?.products.map((product) => {
        if ('variantId' in product && typeof product.variantId === 'string') {
          return {
            productId: product.productId,
            variantId: product.variantId,
            quantity: product.quantity,
          } satisfies ShopifyProduct;
        }
        return product;
      }) ?? []
    );
  }, [order]);

  const refundAmount = useMemo(() => {
    if (!item) return undefined;

    const transformedType = constantCase(item.type);

    if (transformedType === BillingItemType.Refund) {
      const [amount, currency] = item.amount.split(' ');
      const value = Number(amount.replace('$', '').replace(',', '')) * 100;

      return {
        __typename: 'Amount',
        value,
        currency: currency as Currency,
      } satisfies Amount;
    }
    return undefined;
  }, [item]);

  if (!order || !item) {
    return null;
  }

  const transformedType = constantCase(item.type);

  const isCommission =
    transformedType === BillingItemType.AffiliateCommission ||
    transformedType === BillingItemType.MarkupCommission ||
    transformedType === BillingItemType.MerchantCommission ||
    transformedType === BillingItemType.DiscountCommission;

  const renderCommissionSection = () => {
    let rate = order.commission?.rate;
    let status = order.commission?.status;

    const subtotalCents = order.breakdown?.subtotalCents;

    if (!subtotalCents) {
      return <></>;
    }

    if (!rate && transformedType === BillingItemType.MarkupCommission) {
      rate = Math.round(
        ((Number(item.amount.replaceAll(' USD', '').replaceAll('$', '')) * 100) / subtotalCents) *
          100,
      );
    }

    if (
      !status &&
      transformedType === BillingItemType.MarkupCommission &&
      user?.isApprovedForDirectCheckout
    ) {
      status = CommissionStatus.Sent;
    }

    if (!rate || !isCommission) {
      return <></>;
    }

    return (
      <Section>
        <SectionHeader>
          <SheetTitle>
            Commission details{' '}
            <span className="text-muted-foreground">({order.breakdown?.currency})</span>
          </SheetTitle>
        </SectionHeader>
        <SectionContent>
          <CommissionCard
            rate={rate}
            type={item.type}
            status={status ?? CommissionStatus.Pending}
            amount={item.amount.split(' ')[0]}
          />
        </SectionContent>
      </Section>
    );
  };

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent className="w-6/12 overflow-y-auto">
        <SheetHeader className="space-y-2">
          <CardTitle>Billing Item #{item.id}</CardTitle>
          <SheetDescription>Order Id • {item.orderId}</SheetDescription>
        </SheetHeader>

        <SectionContainer>
          {order.breakdown && (
            <Section>
              <SectionHeader>
                <SheetTitle>
                  Order Breakdown{' '}
                  <span className="text-muted-foreground">({order.breakdown?.currency})</span>
                </SheetTitle>
              </SectionHeader>
              <SectionContent>
                <OrderBreakdownCard breakdown={order.breakdown} refundAmount={refundAmount} />
              </SectionContent>
            </Section>
          )}

          {renderCommissionSection()}

          <Section>
            <SectionHeader>
              <SheetTitle>Products</SheetTitle>
            </SectionHeader>
            <SectionContent>
              <ScrollArea className="mb-[24px] whitespace-nowrap rounded-md border">
                <div className="flex w-max space-x-4 p-4">
                  {products?.map((product) => (
                    <ProductCard
                      className="w-[250px]"
                      key={product.productId}
                      productId={product.productId}
                      variantId={'variantId' in product ? product.variantId : undefined}
                      marketplace={order.marketplace === Marketplace.Amazon ? 'amazon' : 'shopify'}
                    />
                  ))}
                </div>
                <ScrollBar orientation="horizontal" />
              </ScrollArea>
            </SectionContent>
          </Section>
        </SectionContainer>
      </SheetContent>
    </Sheet>
  );
};
