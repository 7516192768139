import { Amount, OrderBreakdown } from 'app/graphql/generated/admin/graphql';
import {
  Breakdown,
  BreakdownDivider,
  BreakdownItem,
  BreakdownItemContent,
  Container,
} from './styles';
import { Currency } from 'firebase/analytics';
import { useMemo } from 'react';

type Props = {
  breakdown: OrderBreakdown;
  refundAmount?: Amount;
};
export const OrderBreakdownCard = ({ breakdown, refundAmount }: Props) => {
  const totalCents = useMemo(() => {
    if (refundAmount) {
      return breakdown.totalCents - refundAmount.value;
    }
    return breakdown.totalCents;
  }, []);

  const renderBreakdownLine = (key: string, value: string | Currency) => {
    switch (key) {
      case 'subtotalCents':
        return (
          <BreakdownItemContent>
            <div>Subtotal</div>
            <BreakdownDivider />
            <div>
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: breakdown.currency,
              }).format(Number(value) / 100)}
            </div>
          </BreakdownItemContent>
        );
      case 'taxCents':
        return (
          <BreakdownItemContent>
            <div>Tax</div>
            <BreakdownDivider />
            <div>
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: breakdown.currency,
              }).format(Number(value) / 100)}
            </div>
          </BreakdownItemContent>
        );
      case 'shippingCents':
        return (
          <BreakdownItemContent>
            <div>Shipping</div>
            <BreakdownDivider />
            <div>
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: breakdown.currency,
              }).format(Number(value) / 100)}
            </div>
          </BreakdownItemContent>
        );
      case 'discountCents':
        return (
          <BreakdownItemContent>
            <div>Discount</div>
            <BreakdownDivider />
            <div>
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: breakdown.currency,
              }).format(Number(value) / 100)}
            </div>
          </BreakdownItemContent>
        );
      case 'refundCents':
        return (
          <BreakdownItemContent>
            <div>Refund Amount</div>
            <BreakdownDivider />
            <div>
              {Number(value) && '-'}
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: breakdown.currency,
              }).format(Number(value) / 100)}
            </div>
          </BreakdownItemContent>
        );
      case 'totalCents':
        return (
          <BreakdownItemContent>
            <div>Total</div>
            <BreakdownDivider />
            <div>
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: breakdown.currency,
              }).format(Number(value) / 100)}
            </div>
          </BreakdownItemContent>
        );
    }
    return <></>;
  };
  return (
    <Container className="rounded-lg border">
      <Breakdown>
        <BreakdownItem>
          {renderBreakdownLine('subtotalCents', breakdown.subtotalCents)}
        </BreakdownItem>
        <BreakdownItem>{renderBreakdownLine('taxCents', breakdown.taxCents)}</BreakdownItem>
        <BreakdownItem>
          {renderBreakdownLine('shippingCents', breakdown.shippingCents)}
        </BreakdownItem>
        <BreakdownItem>
          {renderBreakdownLine('discountCents', breakdown.discountCents)}
        </BreakdownItem>
        {refundAmount && (
          <BreakdownItem>{renderBreakdownLine('refundCents', refundAmount.value)}</BreakdownItem>
        )}
        <BreakdownItem>{renderBreakdownLine('totalCents', totalCents)}</BreakdownItem>
      </Breakdown>
    </Container>
  );
};
