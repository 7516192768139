import axios from 'axios';
import { toast } from 'react-toastify';
import { RyeWebhook } from './types';
import { generateHmacSignature } from './util';

const WEBHOOK_TIMEOUT_MS = 10_000;

interface DispatchWebhookParams {
  webhookURL: string;
  payload: RyeWebhook;
  developerId: string;
  hmacSecretKey: string;
}

export async function dispatchWebhook({
  webhookURL,
  payload,
  developerId,
  hmacSecretKey,
}: DispatchWebhookParams) {
  const hmacSignature = generateHmacSignature(JSON.stringify(payload), hmacSecretKey);

  try {
    await axios.post(webhookURL, payload, {
      timeout: WEBHOOK_TIMEOUT_MS,
      validateStatus: () => true,
      headers: {
        'Rye-Verification': developerId,
        'Rye-Hmac-Signature-V1': hmacSignature,
      },
    });
    toast.success(`Webhook ${payload.type} dispatched`);
  } catch (err) {
    toast.error(`Webhook ${payload.type} dispatch failed`);
    console.error(`Webhook  ${payload.type} dispatch failed`, err);
  }
}
