import { useQuery } from '@apollo/client';
import { INVOICES } from 'app/graphql';
import { useAppSelector } from 'app/store';
import { selectUserInfo } from 'app/store/user';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card';
import { TabsContent } from 'components/ui/tabs';
import { Container, EmptyState, Image } from 'pages/Billing/styles';
import { useEffect, useRef, useState } from 'react';
import { format } from 'date-fns';
import { columns, InvoiceItem } from './column';
import { DataTable } from 'components/ui/data-table';
import { capitalCase } from 'change-case';
import EmptyStateImg from 'assets/images/no-invoices.svg';
import { InvoicesQuery } from 'app/graphql/generated/admin/graphql';
import { Separator } from 'components/ui/separator';
import { BalanceContent, BillingPeriodContent, Label } from './styles';
import { Button } from 'components/ui/button';
import { InvoiceDialog } from '../InvoiceDialog';

type Props = {
  value: string;
};

export const BillingInvoiceTabContent = ({ value }: Props) => {
  const { adminToken } = useAppSelector(selectUserInfo) || {};
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const loadingTimeout = useRef<ReturnType<typeof setTimeout>>();

  const [selectedItem, setSelectedItem] = useState<InvoiceItem | undefined>();

  const { data, loading: queryLoading } = useQuery(INVOICES, {
    context: {
      clientName: 'admin',
    },
    skip: !adminToken,
    variables: {
      filters: { year: new Date().getFullYear().toString() },
    },
  });

  const [tableData, setTableData] = useState<InvoiceItem[]>([]);

  const selectedInvoiceData = selectedItem
    ? data?.invoices.edges[Number(selectedItem.id) - 1]?.node
    : undefined;

  useEffect(() => {
    if (data) {
      setTableData(transformTableData(data));
    }

    loadingTimeout.current = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => {
      clearTimeout(loadingTimeout.current);
    };
  }, [data]);

  const handleRowClick = (row: InvoiceItem) => {
    setSelectedItem(row);
    setOpen(true);
  };

  const handleViewDetails = () => {
    setSelectedItem(tableData[0]);
    setOpen(true);
  };

  const currentInvoice = tableData[tableData.length - 1];

  const getCurrentStatusTag = () => {
    switch (currentInvoice?.status) {
      case 'Paid':
        return 'Invoice has been paid';
      case 'Unpaid':
        return 'Invoice finalized and waiting payment';
      default:
        return 'Invoice is being finalized';
    }
  };

  return (
    <TabsContent value={value}>
      <Container>
        <InvoiceDialog
          open={open}
          invoice={selectedInvoiceData}
          rowData={selectedItem}
          onOpenChange={setOpen}
        />
        {currentInvoice && (
          <Card className="mb-[20px] mt-[20px] inline-flex h-[150px] p-[24px]">
            <CardContent className="flex flex-row items-center gap-[70px] p-0">
              <BillingPeriodContent>
                <Label>Billing Period</Label>
                <CardTitle className="font-normal">{currentInvoice.billingPeriod}</CardTitle>
                <CardDescription>{getCurrentStatusTag()}</CardDescription>
              </BillingPeriodContent>

              <Separator orientation="vertical" className="h-[100%]" />

              <BalanceContent>
                <Label>Balance</Label>
                <CardTitle className="font-normal">
                  {currentInvoice.amountDue.split(' ')[0]}
                </CardTitle>
                <CardDescription>Due {format(currentInvoice.dueDate, 'MMMM do')}</CardDescription>
              </BalanceContent>
              <Button onClick={handleViewDetails}>View Invoice Details</Button>
            </CardContent>
          </Card>
        )}
        <Card>
          <CardHeader className="flex-auto flex-row justify-between">
            <div className="space-y-1">
              <CardTitle>Invoice History</CardTitle>
              <CardDescription>List of past invoices</CardDescription>
            </div>
          </CardHeader>
          <CardContent>
            <DataTable
              loading={loading || queryLoading}
              columns={columns}
              data={tableData.filter((_, index) => index !== tableData.length - 1)}
              hideToolbar={true}
              onRowClicked={handleRowClick}
              emptyState={
                <EmptyState>
                  <Image style={{ width: '200px' }} src={EmptyStateImg} />
                  <CardTitle>{'Invoice history is empty...'}</CardTitle>
                </EmptyState>
              }
            />
          </CardContent>
        </Card>
      </Container>
    </TabsContent>
  );
};

function transformTableData(invoices: InvoicesQuery) {
  return (
    invoices.invoices.edges.map((edge, index) => {
      // these dates are utc timestamps
      const startDate = new Date(edge.node.billingPeriod.startDate);
      const endDate = new Date(edge.node.billingPeriod.endDate);
      return {
        id: index + 1,
        billingPeriod: `${format(startDate, 'MMM do')} - ${format(endDate, 'MMM do')}`,
        amountDue: `${new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: edge.node.amount.currency,
        }).format(edge.node.amount.value / 100)} ${edge.node.amount.currency}`,
        dueDate: edge.node.dueDate
          ? format(new Date(edge.node.dueDate), 'MM/dd/yyyy')
          : 'No due date',
        status: capitalCase(edge.node.status),
      };
    }) ?? []
  );
}
