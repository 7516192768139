import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import config, { Routes } from 'config/constants';

import './style.scss';
import { useHypertune } from 'generated/hypertune.react';

interface NavItemProps {
  title: string;
  path: string;
  key: string;
  index: number;
  setRef(index: number, itemRef: React.RefObject<HTMLLIElement>): void;
}

interface ItemProp {
  title: string;
  path: string;
  _ref: React.RefObject<HTMLLIElement> | null;
}

const NAV_ITEMS: ItemProp[] = config.routesNav
  .map((r): ItemProp | undefined => {
    const route = config.routesMap.find((filterR) => filterR.path === r);
    if (route) return { title: route.title, path: route.path, _ref: null };
    return undefined;
  })
  .filter(<T,>(i: T | undefined): i is T => Boolean(i));

const NavItem = ({ index, title, path, setRef }: NavItemProps) => {
  const location = useLocation();
  const itemRef = useRef<HTMLLIElement>(null);
  const isActive = location.pathname.includes(path);
  const classNames = ['nav-item', ...(isActive ? ['selected'] : [])].join(' ');

  useEffect(() => {
    setRef(index, itemRef);
  }, [index, setRef]);

  return (
    <li className={classNames} ref={itemRef}>
      <Link className={clsx('nav-link', isActive && 'font-bold')} to={path}>
        {title}
      </Link>
    </li>
  );
};

const Nav = () => {
  const location = useLocation();
  const [animate, setAnimate] = useState<boolean>(false);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState<number | undefined>(0);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState<number | undefined>(0);
  const hypertune = useHypertune();

  const billingFlag = hypertune.viewBilling({ fallback: false });

  const setItemRef = (index: number, refEl: React.RefObject<HTMLLIElement> | null) => {
    NAV_ITEMS[index]._ref = refEl;
  };
  const getNavItemSize = (item: false | ItemProp): { left: number; width: number } => ({
    left: item ? item._ref?.current?.offsetLeft ?? 0 : 0,
    width: item ? item._ref?.current?.clientWidth ?? 0 : 0,
  });

  const setUnderlineSize = (item: ItemProp) => {
    const { left, width } = getNavItemSize(item);
    setTabUnderlineLeft(left);
    setTabUnderlineWidth(width);
  };

  const positionUnderlineElement = () => {
    const activeItem = computeActiveNavItem(location.pathname);
    if (activeItem) {
      setTimeout(() => setUnderlineSize(activeItem), 50);
    }
    setTimeout(() => setAnimate(Boolean(activeItem)), 100);
  };

  useEffect(() => {
    positionUnderlineElement();

    window.addEventListener('resize', positionUnderlineElement);
    return () => {
      window.removeEventListener('resize', positionUnderlineElement);
    };
  });

  return (
    <nav className="nav-wrapper">
      <ul className={`nav`}>
        {NAV_ITEMS.filter(
          (item: { path: string; title: string }) =>
            (item.path === Routes.Billing && billingFlag) || item.path !== Routes.Billing,
        ).map((item, i) => (
          <NavItem
            index={i}
            key={`items-${item.path}`}
            path={item.path}
            title={item.title}
            setRef={setItemRef}
          />
        ))}
        <li
          className={`nav-underline ${animate ? 'animate' : ''}`}
          style={{
            left: tabUnderlineLeft,
            width: tabUnderlineWidth,
          }}
        ></li>
      </ul>
    </nav>
  );
};

function computeActiveNavItem(pathname: string): ItemProp | undefined {
  return NAV_ITEMS.find((item) => pathname.startsWith(item.path));
}

export default Nav;
