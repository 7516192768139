import { FirebaseApp, initializeApp } from 'firebase/app';
import { Analytics as FirebaseAnalytics, getAnalytics } from 'firebase/analytics';
import {
  getFirestore,
  query,
  getDocs,
  setDoc,
  doc,
  collection,
  addDoc,
  Firestore,
  QueryConstraint,
  QuerySnapshot,
  updateDoc,
  UpdateData,
  connectFirestoreEmulator,
  getDoc,
  DocumentSnapshot,
} from 'firebase/firestore';

import { firebaseConfig } from 'config/firebase';
import { UserModel } from 'app/firebase/collections/users';
import { InventoryRequestsModel } from 'app/firebase/collections/inventoryRequests';
import type { CheckoutRequestFirestore } from '@rye-com/rye-data-layer';
import { env } from 'config/env';
import { connectAuthEmulator, getAuth } from 'firebase/auth';

interface GetRefProps {
  collectionIdAddon?: string;
  condition?: QueryConstraint;
  orderBy?: QueryConstraint;
  limit?: QueryConstraint;
  where?: QueryConstraint;
  startAt?: QueryConstraint;
  endAt?: QueryConstraint;
}

export enum CollectionId {
  Users = 'users',
  InventoryRequests = 'inventoryRequests',
  AmazonBusinessAccounts = 'amazonBusinessAccounts',
}

type CollectionModel = UserModel | InventoryRequestsModel | CheckoutRequestFirestore;

class Firebase {
  app: FirebaseApp;
  analytics: FirebaseAnalytics;
  db: Firestore;

  constructor() {
    const { app, analytics, db } = this.initialize();
    this.app = app;
    this.analytics = analytics;
    this.db = db;
  }

  initialize() {
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const db = getFirestore(app);
    if (env.REACT_APP_NODE_ENV === 'development') {
      connectFirestoreEmulator(db, '127.0.0.1', 8080);
      connectAuthEmulator(getAuth(), 'http://localhost:8087');
    }
    return { app, analytics, db };
  }
}

export const firebase = new Firebase();

export class FirebaseCollection {
  firebase: Firebase;
  collectionId: string;

  constructor(id: CollectionId) {
    this.firebase = firebase;
    this.collectionId = id;
  }

  private getDocRefs = async <T>({ collectionIdAddon = '', ...args }: GetRefProps) => {
    const collectionRef = collection(this.firebase.db, `${this.collectionId}${collectionIdAddon}`);

    const filteredArgs = Object.keys(args).reduce((result, key) => {
      const item = args[key as keyof typeof args];
      if (item) result.push(item);
      return result;
    }, [] as QueryConstraint[]);
    const collectionQuery = query(collectionRef, ...filteredArgs);

    const querySnapshot = (await getDocs(collectionQuery || collectionRef)) as QuerySnapshot<T>;
    return querySnapshot?.docs || [];
  };

  private getDocSnapshot = async <T>(path: string) => {
    const docRef = doc(this.firebase.db, `${this.collectionId}${path}`);

    const docSnapshot = (await getDoc(docRef)) as unknown as DocumentSnapshot<T>;
    return docSnapshot;
  };

  list = async <T>({
    collectionIdAddon = '',
    condition,
    orderBy,
    limit,
    where,
  }: GetRefProps): Promise<{ id: string; data: T }[]> => {
    const docRefs = await this.getDocRefs<T>({
      collectionIdAddon,
      condition,
      orderBy,
      limit,
      where,
    });
    return docRefs.map((docRef) => ({
      id: docRef?.id,
      data: docRef?.data(),
    }));
  };

  get = async <T>(path: string): Promise<T | undefined> => {
    const docRef = await this.getDocSnapshot<T>(path);
    return docRef.data();
  };

  add = async (data: CollectionModel, collectionIdAddon = '') => {
    const collectionRef = collection(this.firebase.db, `${this.collectionId}${collectionIdAddon}`);
    return await addDoc(collectionRef, data);
  };

  set = async (data: CollectionModel, docId = '', collectionIdAddon = '') => {
    const collectionRef = collection(this.firebase.db, `${this.collectionId}${collectionIdAddon}`);
    const docRef = doc(collectionRef, docId);
    return await setDoc(docRef, data);
  };

  update = async <T>(condition: QueryConstraint, data: UpdateData<T>) => {
    const docRefs = await this.getDocRefs<T>({ condition });
    return (
      await Promise.all(
        docRefs.map(async (doc) => {
          await updateDoc<T>(doc?.ref, data);
          return await this.list<T>({ condition });
        }),
      )
    )[0];
  };
}
