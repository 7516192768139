import { gql } from '@apollo/client';
import { identifyURLMarketplace } from 'app/utils';
import { Marketplace } from './types';
import { graphql } from '../generated/cart';

/**
 * RequestProductByURL
 * */
export const REQUEST_PRODUCT_BY_URL = gql`
  mutation RequestProductByURL($input: RequestProductByURLInput!) {
    requestProductByURL(input: $input) {
      productID
    }
  }
`;

export const getRequestProductionByURLInput = (url: string) => {
  const marketplace = identifyURLMarketplace(url);
  return marketplace && { input: { url, marketplace } };
};

/**
 * RequestStoreByURL
 * */
export const REQUEST_STORE_BY_URL = gql`
  mutation RequestStoreByURL($input: RequestStoreByURLInput!) {
    requestStoreByURL(input: $input) {
      requestID
    }
  }
`;

export const getRequestShopByURLInput = (url: string) => {
  return { input: { url } };
};

/**
 * RequestShopifyProductById
 * */
export const REQUEST_SHOPIFY_PRODUCT_BY_ID = gql`
  query RequestShopifyProductByID($input: ProductByIDInput!) {
    productByID(input: $input) {
      id
      marketplace
      title
      description
      vendor
      url
      images {
        url
      }
      variants {
        title
        image {
          url
          ... on ShopifyImage {
            position
            width
            height
          }
        }
        ... on ShopifyVariant {
          id
        }
      }
      price {
        value
        currency
        displayValue
      }
      ... on ShopifyProduct {
        id
        marketplace
        storeCanonicalURL
        title
        description
        vendor
        url
        price {
          value
          currency
          displayValue
        }
      }
    }
  }
`;

export const getRequestShopifyProductInputById = (id: string) => {
  return { input: { id, marketplace: Marketplace.SHOPIFY } };
};

/**
 * RequestMarketplaceProductVendorByID
 * */
export const REQUEST_MARKETPLACE_PRODUCT_VENDOR_BY_ID = gql`
  query RequestMarketplaceProductVendorByID($input: ProductByIDInput!) {
    productByID(input: $input) {
      vendor
    }
  }
`;

export const getMarketplaceProductVendorInputById = (id: string, marketplace: string) => {
  return { input: { id, marketplace: marketplace.toUpperCase() } };
};

/**
 * RequestAmazonProductById
 * */
export const REQUEST_AMAZON_PRODUCT_BY_ID = gql`
  query RequestAmazonProductByID($input: ProductByIDInput!) {
    productByID(input: $input) {
      id
      marketplace
      title
      description
      vendor
      url
      images {
        url
      }
      variants {
        title
        image {
          url
        }
      }
      price {
        value
        currency
        displayValue
      }
      ... on AmazonProduct {
        id
        marketplace
        title
        description
        vendor
        url
        price {
          value
          currency
          displayValue
        }
      }
    }
  }
`;

export const GET_SPREEDLY_ENV_TOKEN = gql`
  query EnvironmentToken {
    environmentToken {
      token
    }
  }
`;

export const getRequestAmazonProductInputById = (id: string) => {
  return { input: { id, marketplace: Marketplace.AMAZON } };
};

export const ORDER_BY_ID = gql`
  query OrderByID($id: ID!) {
    orderByID(id: $id) {
      id
      cartId
      status
      marketplaceOrderIds
      marketplace
      metadata {
        ... on AmazonOrderMetadata {
          account {
            email
            groupId
          }
        }
      }
      shipments {
        carrierName
        carrierTrackingNumber
        carrierTrackingUrl
        status
      }
      lineItems {
        ... on AmazonLineItem {
          productId
          quantity
          price {
            value
            currency
          }
          amazonBusinessPrice {
            value
            currency
          }
        }
        ... on ShopifyLineItem {
          quantity
          variantId
          productId
        }
      }
      returns {
        id
        shippingLabelUrl
        marketplaceOrderId
        marketplace
        orderId
        lineItems {
          ... on AmazonReturnLineItem {
            productId
            status
            quantity
          }
          ... on ShopifyReturnLineItem {
            variantId
            quantity
            status
          }
        }
      }
      requiredActions {
        __typename
      }
      events {
        __typename
        id
        createdAt
        ... on OrderFailedOrderEvent {
          reason
        }
      }
    }
  }
`;

export const CREATE_ORDER_REQUEST_PRODUCT_BY_URL = graphql(`
  mutation CreateOrder_RequestProductByURL($input: RequestProductByURLInput!) {
    requestProductByURL(input: $input) {
      productID
    }
  }
`);

export const GET_PRODUCT_BY_ID = gql`
  query CreateOrder_ProductById($input: ProductByIDInput!) {
    productByID(input: $input) {
      id
      title
      description
      isAvailable
      images {
        url
      }
      marketplace
      price {
        displayValue
        value
        currency
      }
      variants {
        id
        ... on ShopifyVariant {
          name
          title
          isAvailable
          priceCents
          image {
            url
          }
        }
      }
      vendor
    }
  }
`;

export const cartDetails = gql`
  fragment CreateOrder_priceInfo on Price {
    currency
    displayValue
    value
  }

  fragment CreateOrder_cartDetails on CartResponse {
    cart {
      id
      buyerIdentity {
        firstName
        lastName
        address1
        city
        provinceCode
        postalCode
      }
      cost {
        subtotal {
          ...CreateOrder_priceInfo
        }
        tax {
          ...CreateOrder_priceInfo
        }
        shipping {
          ...CreateOrder_priceInfo
        }
        total {
          ...CreateOrder_priceInfo
        }
      }
      stores {
        ... on AmazonStore {
          store
          errors {
            code
            message
          }
          offer {
            errors {
              code
              message
            }
            shippingMethods {
              id
            }
          }
          shipsToCountries
        }
        ... on ShopifyStore {
          store
          errors {
            code
            message
          }
          offer {
            errors {
              code
              message
            }
            shippingMethods {
              id
            }
          }
          shipsToCountries
        }
      }
    }
    errors {
      code
      message
    }
  }
`;

export const CREATE_CART = gql`
  mutation CreateOrder_CreateCart($input: CartCreateInput!) {
    createCart(input: $input) {
      ...CreateOrder_cartDetails
    }
  }
`;

export const GET_CART = gql`
  query CreateOrder_GetCart($id: ID!) {
    getCart(id: $id) {
      ...CreateOrder_cartDetails
    }
  }
`;

export const UPDATE_BUYER_IDENTITY = gql`
  mutation CreateOrder_UpdateCartBuyerIdentity($input: CartBuyerIdentityUpdateInput!) {
    updateCartBuyerIdentity(input: $input) {
      ...CreateOrder_cartDetails
    }
  }
`;

export const SUBMIT_CART = gql`
  mutation CreateOrder_SubmitCart($input: CartSubmitInput!) {
    submitCart(input: $input) {
      cart {
        stores {
          errors {
            code
            message
          }
          orderId
        }
      }
      errors {
        code
        message
      }
    }
  }
`;
