import { CommissionStatus } from 'app/graphql/generated/admin/graphql';
import { CommissionValue, Container } from './styles';
import {
  Breakdown,
  BreakdownDivider,
  BreakdownItem,
  BreakdownItemContent,
} from '../OrderBreakdownCard/styles';
import { capitalCase } from 'change-case';

type Props = {
  rate: number;
  status: CommissionStatus;
  amount: string;
  type: string;
};
export const CommissionCard = ({ rate, status, amount, type }: Props) => {
  const renderCommissionLine = (key: string, value: string | number) => {
    switch (key) {
      case 'amount':
        return (
          <BreakdownItemContent>
            <div>Earned</div>
            <BreakdownDivider />
            <CommissionValue>{value}</CommissionValue>
          </BreakdownItemContent>
        );
      case 'status':
        return (
          <BreakdownItemContent>
            <div>Status</div>
            <BreakdownDivider />
            <CommissionValue>{capitalCase(`${value}`)}</CommissionValue>
          </BreakdownItemContent>
        );
      case 'rate':
        return (
          <BreakdownItemContent>
            <div>Rate</div>
            <BreakdownDivider />
            <CommissionValue>{value}%</CommissionValue>
          </BreakdownItemContent>
        );
      case 'type':
        return (
          <BreakdownItemContent>
            <div>Type</div>
            <BreakdownDivider />
            <CommissionValue>{capitalCase(`${value}`)}</CommissionValue>
          </BreakdownItemContent>
        );
    }
    return <></>;
  };
  return (
    <Container className="rounded-lg border">
      <Breakdown>
        <BreakdownItem>{renderCommissionLine('type', type)}</BreakdownItem>
        <BreakdownItem>{renderCommissionLine('rate', rate)}</BreakdownItem>
        <BreakdownItem>{renderCommissionLine('amount', amount)}</BreakdownItem>
        <BreakdownItem>{renderCommissionLine('status', status)}</BreakdownItem>
      </Breakdown>
    </Container>
  );
};
