'use client';

import * as React from 'react';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { trackSegmentEvent } from 'app/utils';

import { cn } from 'lib/utils';
import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar';
import { Button } from 'components/ui/button';

import useAuth from 'hooks/useAuth';
import { useAppSelector } from 'app/store';
import { selectUserInfo } from 'app/store/user';
import { useState } from 'react';
import { ThemeMode } from 'providers/ThemeProvider';
import useTheme from 'hooks/useTheme';
import { ACTION, SOURCE } from 'app/analytics';
import { getSwitchLink } from 'config/constants';
import { env } from 'config/env';
import { useHypertune } from 'generated/hypertune.react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import { DevelopersDialog, OriginalAccount } from './components/DevelopersDialog';
import { localStorageAdminAccount } from 'app/localstorage';

type DropdownMenuProps = React.ComponentPropsWithoutRef<typeof DropdownMenuTrigger>;

interface AccountSwitcherProps extends DropdownMenuProps {}

export default function AccountDropdown({ className }: AccountSwitcherProps) {
  const { theme, switchTheme } = useTheme();

  const { displayName, email, photoURL, apiKey } = useAppSelector(selectUserInfo) || {};

  const hypertune = useHypertune();
  const isAdminUser = hypertune.ryeAdminAccount({ fallback: false });
  const originalAccount = localStorageAdminAccount.get<OriginalAccount>();

  const { logout, assumeDeveloperIdentity } = useAuth();

  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleSwitchThemeClick = () => {
    switchTheme();
    trackSegmentEvent(SOURCE.CONSOLE_MENU, ACTION.CLICK, 'theme_button');
    setOpen(false);
  };

  const handleLogoutClick = () => {
    logout();
    trackSegmentEvent(SOURCE.CONSOLE_MENU, ACTION.CLICK, 'logout_button');
  };

  const switchLabel = () => {
    switch (env.REACT_APP_NODE_ENV) {
      case 'staging':
      case 'development':
        return 'Production';
      default:
        return 'Staging';
    }
  };

  const handleEnvSwitch = () => {
    window.location.href = getSwitchLink();
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
    setOpen(false);
  };

  const handleBackToAdmin = async () => {
    console.log(originalAccount);
    if (!originalAccount?.id || !originalAccount?.token) {
      return;
    }

    await assumeDeveloperIdentity(originalAccount.id, apiKey, originalAccount.token, false);
    localStorageAdminAccount.remove();
    setOpen(false);
    window.location.reload();
  };

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DevelopersDialog
        open={openDialog}
        onOpenChange={setOpenDialog}
        onLogin={() => window.location.reload()}
      />
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          aria-label="User account dropdown"
          className={cn('w-[250px] justify-between', className)}
        >
          <Avatar className="mr-2 h-5 w-5">
            <AvatarImage src={photoURL ?? undefined} alt="user image" className="grayscale" />
            <AvatarFallback>
              {displayName?.[0]?.toUpperCase() || email?.[0]?.toUpperCase() || 'U'}
            </AvatarFallback>
          </Avatar>
          {displayName || email || 'User'}
          <ChevronDownIcon className="ml-auto h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-[250px]">
        <DropdownMenuGroup>
          {isAdminUser && !originalAccount ? (
            <DropdownMenuItem onClick={handleDialogOpen}>
              Assume Developer's identity
            </DropdownMenuItem>
          ) : (
            originalAccount?.id &&
            originalAccount.token && (
              <DropdownMenuItem onClick={handleBackToAdmin}>
                Go back to original account
              </DropdownMenuItem>
            )
          )}
          <DropdownMenuItem
            onClick={handleEnvSwitch}
          >{`Switch to ${switchLabel()}`}</DropdownMenuItem>
          <DropdownMenuItem onClick={handleSwitchThemeClick}>{`Switch to ${
            theme === ThemeMode.Light ? ThemeMode.Dark : ThemeMode.Light
          } mode`}</DropdownMenuItem>
          <DropdownMenuItem onClick={handleLogoutClick}>Logout</DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
