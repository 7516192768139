import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/ui/dialog';
import * as Sentry from '@sentry/react';
import { AddNotificationLabel, NotificationContent, SubmitButton } from './style';
import { useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client/react';
import { UPDATE_BILLING_NOTIFICATIONS } from 'app/graphql';
import { useAppSelector } from 'app/store';
import { selectUserInfo } from 'app/store/user';
import { LoadingOutlined } from '@ant-design/icons';
import useTheme from 'hooks/useTheme';
import { BillingNotifications } from 'app/graphql/generated/admin/graphql';
import { isEmail } from 'class-validator';
import { Label } from 'components/ui/label';
import { EmailTagInput } from 'components/EmailTagInput';

type Props = {
  onSubmit: (billingNotifications: BillingNotifications) => void;
};

export const NotificationSettingsDialog = ({ onSubmit }: Props) => {
  const userState = useAppSelector(selectUserInfo);
  const loadingTimerRef = useRef<ReturnType<typeof setTimeout> | undefined>();
  const [emails, setEmails] = useState<string[]>(
    userState?.billingNotificationSettings?.emails || [],
  );

  useEffect(() => {
    setEmails(userState?.billingNotificationSettings?.emails || []);

    return () => {
      clearTimeout(loadingTimerRef.current);
    };
  }, [userState?.billingNotificationSettings]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const { theme } = useTheme();

  const [updateBillingNotifications] = useMutation(UPDATE_BILLING_NOTIFICATIONS, {
    context: { clientName: 'admin' },
  });

  const handleSubmit = async () => {
    if (userState?.isReadOnly) {
      return;
    }

    setIsSubmitting(true);

    const res = await updateBillingNotifications({
      variables: {
        input: {
          emails: emails.map((email) => email),
        },
      },
    });
    if (res.errors) {
      Sentry.captureException(res.errors);
      return;
    }

    const billingNotifications = res.data?.updateBillingNotifications.settings;
    if (!billingNotifications) {
      Sentry.captureException('No billing notifications returned');
      return;
    }

    loadingTimerRef.current = setTimeout(() => {
      setIsSubmitting(false);
      setOpen(false);
      onSubmit(billingNotifications);
    }, 1000);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {!userState?.isReadOnly && (
        <DialogTrigger disabled={userState?.isReadOnly}>
          <AddNotificationLabel>
            <span>Update Notification Settings</span>
          </AddNotificationLabel>
        </DialogTrigger>
      )}
      <DialogContent style={{ borderColor: '#333' }}>
        <DialogHeader>
          <DialogTitle>Notification Settings</DialogTitle>
          <DialogDescription>Update billing notification settings</DialogDescription>
        </DialogHeader>
        <NotificationContent className="space-y-1">
          <Label>Additional Invoice Recipients</Label>
          <EmailTagInput
            emails={emails}
            setEmails={setEmails}
            disabled={userState?.isReadOnly || isSubmitting}
            readOnly={userState?.isReadOnly || isSubmitting}
            addTagsOnBlur={true}
            inputProps={{ inputMode: 'email' }}
            placeholder="Add Email"
            styleClasses={{
              input: 'w-full sm:max-w-[350px] shadow-none',
              tag: {
                body: 'bg-[rgba(78,213,88,35%)] text-gray-950 border-0 hover:bg-[rgba(78,213,88,35%)]',
              },
              tagList: { container: 'flex-wrap' },
            }}
            variant="primary"
            validateTag={(tag) => {
              return isEmail(tag);
            }}
          />
        </NotificationContent>
        <DialogFooter>
          <SubmitButton disabled={isSubmitting} onClick={handleSubmit} thememode={theme}>
            {isSubmitting ? <LoadingOutlined /> : 'Save'}
          </SubmitButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
