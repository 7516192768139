'use client';

import { ColumnDef } from '@tanstack/react-table';

// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.
export type ActivityItem = {
  id: number;
  amount: string;
  date: string;
  payer: string;
  type: string;
  orderId: string;
};

export const columns: ColumnDef<ActivityItem>[] = [
  {
    accessorKey: 'id',
    header: 'Id',
  },
  {
    accessorKey: 'date',
    header: 'Date',
  },
  {
    accessorKey: 'orderId',
    header: 'Order Id',
  },
  {
    accessorKey: 'amount',
    header: 'Amount',
  },
  {
    accessorKey: 'payer',
    header: 'Payer',
  },
  {
    accessorKey: 'type',
    header: 'Type',
  },
];
